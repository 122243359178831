<template>
  <div>
    <milk-product-add-new
      :isProductSelected="true"
      :productName="product.name"
      :is-add-new-raw-milk-sidebar-active.sync="isAddNewRawMilkSidebarActive"
      @refetch-data-summary="refecthMilkProduct"
    />

    <milk-product-edit
      :is-edit-milk-product-sidebar-active.sync="isEditMilkProductSidebarActive"
      :product="productEdit"
      @refetch-data="refecthMilkProduct"
    />
    
    <b-card
    no-body
    class="mb-0 mt-3"
  >

      <!-- Table Top -->
      <b-row class="pt-2 pl-2 pr-2">  
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <h4>History Produk {{product.name}}</h4>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
        >
          
          <b-button
              variant="primary"
              @click="isAddNewRawMilkSidebarActive = true"
            >
              <span class="text-nowrap">+ Add Record</span>
            </b-button>
        </b-col>
      </b-row>
      <hr/>
      <b-row class="pl-2 pr-2 pb-2">
        <!-- Per Page -->
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
        <b-form-input
        v-model="searchQueryMilkProduct"
        class="d-inline-block mr-1"
        placeholder="Search..."
      />
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            
            
          </div>
        </b-col>
      </b-row>

    <b-table
      ref="refMilkProductTable"
      class="position-relative"
      :items="fetchMilkProduct"
      responsive
      :fields="tableColumnMilkProducts"
      primary-key="id"
      :sort-by.sync="sortByMilkProduct"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDescRawMilkSummary"
    >

      <!-- Column: Actions -->
      <template #cell(aksi)="data">
        <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                />
            </template>
              <b-dropdown-item @click="handleEditData(data.item); isEditMilkProductSidebarActive = !isEditMilkProductSidebarActive">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item @click="handleDelete(data.item.id)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50" >Delete</span>
              </b-dropdown-item>
        </b-dropdown>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMetaRawMilkSummary.from }} to {{ dataMetaRawMilkSummary.to }} of {{ dataMetaRawMilkSummary.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <!-- <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPageRawMilkSummary"
            :total-rows="totalMilkProductList"
            :per-page="perPageRawMilkSummary"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col> -->

      </b-row>
    </div>
  </b-card>
  </div>
</template>

<script>
import { 
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination, } from 'bootstrap-vue'

// import GoatProfile from './GoatProfile.vue'
// import GoatHero from './GoatHero.vue'
import useMilkProductList from './useMilkProductList'
import milkProductStoreModule from '../milkProductStoreModule'
import MilkProductAddNew from '../MilkProductAddNew.vue'
import MilkProductEdit from './MilkProductEdit.vue'


import { ref, onUnmounted } from '@vue/composition-api'

import vSelect from 'vue-select'
import store from '@/store'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    
    // GoatProfile,
    // GoatHero,

    MilkProductAddNew,
    MilkProductEdit,
  },
  data() {
    return {
      product: {},
    }
  },
  created(){
    this.$http.get('/milk/productions/categories/' + this.$router.currentRoute.params.id)
    .then((response) => {
      const {id, category, unit} = response.data.data
      this.product = {
        id,
        name: category,
        unit
      }
    })
  },
  setup() {
    const toast = useToast()

    const DETAIL_MILK_PRODUCT_APP_STORE_MODULE_NAME = 'app-milk-products'

    // Register module
    if (!store.hasModule(DETAIL_MILK_PRODUCT_APP_STORE_MODULE_NAME)) store.registerModule(DETAIL_MILK_PRODUCT_APP_STORE_MODULE_NAME, milkProductStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DETAIL_MILK_PRODUCT_APP_STORE_MODULE_NAME)) store.unregisterModule(DETAIL_MILK_PRODUCT_APP_STORE_MODULE_NAME)
    })

    const isAddNewRawMilkSidebarActive = ref(false)
    const isEditMilkProductSidebarActive = ref(false);
    

    const {
      fetchMilkProduct,
      tableColumnMilkProducts,
      perPageRawMilkSummary,
      currentPageRawMilkSummary,
      totalMilkProductList,
      dataMetaRawMilkSummary,
      perPageOptionsRawMilkSumarry,
      searchQueryMilkProduct,
      sortByMilkProduct,
      isSortDirDescRawMilkSummary,
      refMilkProductTable,

      refecthMilkProduct,
    } = useMilkProductList()

    // handle edit
    const blankMilkProduct = {
      id: null,
      volume_in: null,
      volume_out: null,
      date: null,
      description: '',
    }
    
    const productEdit = ref(JSON.parse(JSON.stringify(blankMilkProduct)))

    const handleEditData = (product) => {
      productEdit.value.id = product.id;
      productEdit.value.volume_in = product.total_produksi;
      productEdit.value.volume_out = product.total_stok_keluar;
      productEdit.value.date = product.tanggal;
      productEdit.value.description = product.ket;
    }

    const handleDelete = async (id) => {
      await store.dispatch(`app-milk-products/deleteStock`, {id})
      .then(() => {
        // show success toast  
        toast({
          component: ToastificationContent,
          props: {
            title: 'Berhasil menghapus catatan stok produk',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        refecthMilkProduct();
      })
    }
    
    return {
      
      isAddNewRawMilkSidebarActive,

      fetchMilkProduct,
      tableColumnMilkProducts,
      perPageRawMilkSummary,
      currentPageRawMilkSummary,
      totalMilkProductList,
      dataMetaRawMilkSummary,
      perPageOptionsRawMilkSumarry,
      searchQueryMilkProduct,
      sortByMilkProduct,
      isSortDirDescRawMilkSummary,
      refMilkProductTable,

      refecthMilkProduct,
      isEditMilkProductSidebarActive,
      handleEditData,
      productEdit,

      handleDelete,
    }
  },
}
</script>


<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
