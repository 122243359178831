<template>
  <b-sidebar
    id="edit-milk-product-sidebar"
    :visible="isEditMilkProductSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-edit-milk-product-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Edit Catatan Produk
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

          <!-- Volume Susu Mentah -->
          <validation-provider
            #default="validationContext"
            name="Jumlah Masuk"
          >
            <b-form-group
              label="Volume Produksi Masuk"
              label-for="volume_in"
            >
              <b-form-input
                id="volume_in"
                v-model="product.volume_in"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Volume_in Produksi"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Volume Susu Mentah -->
          <validation-provider
            #default="validationContext"
            name="Jumlah Masuk"
          >
            <b-form-group
              label="Volume Produksi Keluar"
              label-for="volume_out"
            >
              <b-form-input
                id="volume_out"
                v-model="product.volume_out"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Jumlah volume keluar"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Tanggal Penggunaan -->
          <validation-provider
            #default="validationContext"
            name="Tanggal"
            rules="required"
          >
            <b-form-group
              label="Tanggal"
              label-for="date"
            >
              <b-form-datepicker
                id="date"
                placeholder="Choose a date"
                v-model="product.date"
                :state="getValidationState(validationContext)"
                trim
              />
              
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Deskripsi -->
          <validation-provider
          #default="validationContext"
          name="Deskripsi">

          <b-form-group
            label="Deskripsi"
            label-for="description"
            :state="getValidationState(validationContext)"
          >
            <b-form-input
              id="description"
              v-model="product.description"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              placeholder="Deskripsi"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isEditBtnClicked"
              @click="isEditBtnClicked = !isEditBtnClicked"
              v-if="$can('update', 'milk_product')"
            >
            <span v-if="!isEditBtnClicked">Edit</span>
            <b-spinner small v-if="isEditBtnClicked" />
            <span v-if="isEditBtnClicked">Loading...</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Batal
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { BSpinner, BSidebar, BForm, BFormGroup, BFormInput,BFormRadioGroup, BFormInvalidFeedback, BButton, BFormDatepicker, BFormTimepicker } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axios from '@axios'


import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSpinner,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormTimepicker,
    BFormInvalidFeedback,
    BButton,
    BFormRadioGroup,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditMilkProductSidebarActive',
    event: 'update:is-edit-milk-product-sidebar-active',
  },
  props: {
    isEditMilkProductSidebarActive: {
      type: Boolean,
      required: true,
    },
    product: {
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      femaleGoatOptions: [],
    }
  },
  setup(props, { emit }) {
    
    // use Toast
    const toast = useToast()
    const isEditBtnClicked = ref(false)
   
    // when click Add button
    const onSubmit = async () => {      
      await axios.put(`milk/productions/${props.product.id}`, props.product)
      .then((response) => {
        if(response.data.status == 417){
          toast({
          component: ToastificationContent,
          props: {
            title: 'Gagal mengedit susu mentah',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
        
        toast({
          component: ToastificationContent,
          props: {
            title: 'Berhasil mengubah data susu mentah',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        emit('refetch-data')
        emit('update:is-edit-milk-product-sidebar-active', false)
        isEditBtnClicked.value = !isEditBtnClicked.value

      })
  }

    const { refFormObserver, getValidationState, resetForm } = formValidation()

    return {
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      isEditBtnClicked,
    }
  },
  created() {
    
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#add-new-goat-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
