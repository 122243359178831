import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useMilkProductList() {
  // Use toast
  const toast = useToast()

  const refMilkProductTable = ref(null)

  // Table Handlers
  const tableColumnMilkProducts = [
    { key: 'tanggal', sortable: false },
    { key: 'total_produksi', sortable: false },
    { key: 'total_stok_keluar', sortable: false },
    { key: 'stok_tersedia', sortable: false },
    { key: 'ket', sortable: false },
    { key: 'aksi' },
  ]
  const perPageRawMilkSummary = ref(10)
  const totalMilkProductList = ref(0)
  const currentPageRawMilkSummary = ref(1)
  const perPageOptionsRawMilkSumarry = [10, 25, 50, 100]
  const searchQueryMilkProduct = ref('')
  const sortByMilkProduct = ref('id')
  const isSortDirDescRawMilkSummary = ref(true)

  const dataMetaRawMilkSummary = computed(() => {
    const localItemsCount = refMilkProductTable.value ? refMilkProductTable.value.localItems.length : 0
    return {
      from: perPageRawMilkSummary.value * (currentPageRawMilkSummary.value - 1) + (localItemsCount ? 1 : 0),
      to: perPageRawMilkSummary.value * (currentPageRawMilkSummary.value - 1) + localItemsCount,
      of: totalMilkProductList.value,
    }
  })

  const refecthMilkProduct = () => {
    refMilkProductTable.value.refresh()
  }

  watch([currentPageRawMilkSummary, perPageRawMilkSummary, searchQueryMilkProduct,], () => {
    refecthMilkProduct()
  })

  const mapServiceProductToModel = ({id, date, unit, volume_in, volume_out, amount, description}) => ({
    id,
    tanggal: date,
    total_produksi: volume_in ?? null + ' ' + unit ?? null,
    total_stok_keluar: volume_out ?? null + ' ' + unit ?? null,
    satuan: unit ?? null,
    stok_tersedia: amount,
    ket: description,
  });

  const fetchMilkProduct = (ctx, callback) => {
    store
      .dispatch('app-milk-products/fetchProductHistory', {
        id: router.currentRoute.params.id
      })
      .then(response => {
        const data = response.data.data
        const dataMapped = data.map(mapServiceProductToModel);
        const { total } = response.data.data
        callback(dataMapped)
        totalMilkProductList.value = total
      })
      .catch((error) => {
        console.log(error);
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Detail Produk',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchMilkProduct,
    tableColumnMilkProducts,
    perPageRawMilkSummary,
    currentPageRawMilkSummary,
    totalMilkProductList,
    dataMetaRawMilkSummary,
    perPageOptionsRawMilkSumarry,
    searchQueryMilkProduct,
    sortByMilkProduct,
    isSortDirDescRawMilkSummary,
    refMilkProductTable,

    refecthMilkProduct,

  }
}
